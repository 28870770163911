<template>
    <div>
         <label class="form-control-label" v-if="title">{{label}}</label>
        <div class="image-editor" v-if="perm">
            <a-form-item v-bind="formItemLayout" class="image-uploader m-0 float-left w-25">
                <div class="beta-image" v-if="imagetoshow[dat]">
                    <img :src="imagetoshow[dat]" class="w-100" @click="clicked">
                </div>
                <a-upload v-decorator="['upload', { valuePropName: 'fileList', getValueFromEvent: normFile,},]"
                          name="upload"
                          :multiple="false"
                          :customRequest="(e) => testo(e,dat)"
                          :headers="head"
                          :action="url.API_URL +'/upload/content'"
                          list-type="picture" >
                    <a-button><i class="fa fa-upload"></i> Nahrať súbor</a-button>
                </a-upload>
                <a-button type="default" class="remove-button" v-if="datatosave.uri && imagetoshow[dat]" @click="remove"><i class="fa fa-times"></i></a-button>
            </a-form-item>
            <a-form-item  v-if="perm.includes(17)"  class="image-property w-75"   @click.ctrl="$emit('cmdCtrl')" >
                <a-select placeholder="štýl obrázka" class="w-100" :key="'s' + itt + datatosave.style" @change="handleEdit" v-model="datatosave.style" :options="styles"/>
            </a-form-item>
            <a-form-item class="image-property w-75" v-if="customsize">
                <a-input  placeholder="Vlastný rozmer" class="w-100" @change="handleEdit" v-model="datatosave.style"/>
            </a-form-item>
            <a-form-item  class="image-property w-75">
                <a-input placeholder="Názov" class="w-100" @change="handleEdit" v-model="datatosave['title']" />
            </a-form-item>
            <a-form-item  class="image-property w-75">
                <a-input placeholder="Alt tag" class="w-100" @change="handleEdit" v-model="datatosave['alt']" />
            </a-form-item>
        </div></div>
</template>

<script>
    import {getURL} from "../../api";
    import {authHeader} from "../../_helpers";
    import AFormItem from "ant-design-vue/es/form/FormItem";
    import AButton from "ant-design-vue/es/button";
    import AInput from "ant-design-vue/es/input";
    import AUpload from "ant-design-vue/es/upload";
    import {dataService} from "../../_services";

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }

    export default {
        name: "image-uploader",
        components: {AFormItem,AButton,AInput,AUpload},
        props: ["data","dat","values","label","editid","title"],
        data() {
            return {
                imagetoshow: {},
                itt: 0,
                datatosave: {'uri': '','alt': '','fid': '','title': '','editid': this.editid},
                url: getURL(),
                head: authHeader(),
                perm: JSON.parse(localStorage.user).data.permissions,
                styles:[],
                customsize: false,
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 4},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 23},
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: {span: 24, offset: 0},
                        sm: {span: 24, offset: 0},
                    },
                },
                components: [],
                visible: false,
                editVisible: false
            };
        },
        methods: {
            remove(){
                this.imagetoshow =  {};
                this.datatosave =  {'uri': '','alt': '','fid': '','title': '','editid': this.editid};
                this.$emit('changed', this.datatosave);
            },
            sendImage(formData)
            {

                const pa = this;
                this.axios.post(this.url.API_URL + '/upload/content',
                    formData,
                    {
                        headers: authHeader(true)
                    }
                ).then(function (data) {
                    pa.datatosave = data.data[0];
                    pa.$emit('changed',  pa.datatosave);
                });

            },
          hasAlpha (context, canvas) {
        var data = context.getImageData(0, 0, canvas.width, canvas.height).data,
            hasAlphaPixels = false;
        for (var i = 3, n = data.length; i < n; i+=4) {
            if (data[i] < 255) {
                hasAlphaPixels = true;
                break;
            }
        }
        return hasAlphaPixels;
    },
 getExtension(path) {
    var basename = path.split(/[\\/]/).pop(),  // extract file name from full path ...
                                               // (supports `\\` and `/` separators)
        pos = basename.lastIndexOf(".");       // get last position of `.`

    if (basename === "" || pos < 1)            // if file name is empty or ...
        return "";                             //  `.` not found (-1) or comes first (0)

    return basename.slice(pos + 1);            // extract extension ignoring `.`
},
            testo(e,slug)
            {

   const pa = this;

var img = new Image;
    let formData = new FormData();
let filename = e.file.name;
                        if(pa.getExtension(filename) =="svg")
                        {

                             formData.append('upload', e.file);

                               this.axios.post(this.url.API_URL + '/upload/content',
                    formData,
                    {
                        headers: authHeader(true)
                    }
                ).then(function (data) {
                    pa.datatosave = data.data[0];
                    pa.$emit('changed',  pa.datatosave);
                });

                  img.src = URL.createObjectURL(e.file);
                        }
                        else{

                img.onload = function() {

                        formData.append('upload', e.file);

                    pa.sendImage(formData,slug);

                }

                img.src = URL.createObjectURL(e.file);  }
                return false
            }, normFile(e) {

                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;


            },
            handleEdit() {
                this.$emit('changed', this.datatosave);
                this.itt = this.itt + 1;
            },
            clicked: function(event) {
               if(event.ctrlKey)
                   this.customsize = !this.customsize;
            },
        },
        mounted() {
          if(this.data)
                {
          if(this.data.image)
                this.data = this.data.image;
            if(this.data)
            {this.imagetoshow[this.dat] = this.data.uri;
                this.datatosave['uri'] = this.data.uri;
                this.datatosave['fid'] = this.data.fid;
                this.datatosave['alt'] = this.data.alt;
                this.datatosave['style'] = this.data.style;
                this.datatosave['title'] = this.data.title;}
              }}
    }
</script>

<style scoped>
    .element
    {display:block;width:100%;padding:20px;border-bottom:1px solid #eceff3;}
    .top-holder
    {width:100%;  padding:15px   }
    .edit-button
    {float:right;}
    .ant-radio-button-wrapper img
    {width:400px}
    .image-property
    {float:left;}
    .image-property .ant-form-item-control-wrapper
    {width:100%}
    .image-property input
    {width:100%;}
    .image-property select
    {width:100%;}
    .ant-form-item
    {margin-bottom:3px}
    .image-editor label
    {float:left;width:15%;line-height:32px;}
    .image-editor .image-uploader
    {float:left;width:25%;margin-right:1%;}
    .ant-select
    {width:100%}

    .image-uploader
    {position:relative;min-height:110px; max-width:150px;}
    .image-editor .image-uploader >>> span,
    .image-editor .image-uploader >>> .ant-upload.ant-upload-select,
    .image-editor .image-uploader >>> button:not(.remove-button)
    {width:100%;}
    .image-editor .image-style
    {float:left;width:65%}
    .image-editor
    {padding:0;display:inline-block;width:100%;

    }
    .remove-button
    {  float: right;
        position: absolute;
        bottom: 28px;
        right: 0px;
        width: auto;
        text-align: center;
        /* height: auto; */
        border-radius: 0;
    }
    .remove-button i
    {
        line-height:25px;
    }
    .beta-image
    {background-color: rgba(0, 0, 0, 0.07); overflow:hidden;}
    .beta-image img
    {overflow:hidden;width:100%; max-width:150px;display:block; }
</style>
